<template>
  <DropdownComponent v-if="value.length" :placement="placement">
    <ButtonComponent
      theme="primary"
      :icon="['far', 'arrow-down']"
      :label="actionLabel"
      @click="hasMany ? () => {} : download(value[0])"
    />
    <template #dropdown>
      <span></span>
      <template v-if="hasMany">
        <ButtonComponent
          v-for="(item, index) in value"
          :key="index"
          :icon="['fad', 'file-pdf']"
          :label="item.name"
          @click="download(item)"
        />
      </template>
    </template>
  </DropdownComponent>
</template>

<script>
import { Capitalize } from '@/utils'
import { DropdownComponent } from 'vue-elder-dropdown'
import { EventBus as AssetBus } from '@kvass/asset-manager'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    placement: {
      type: String,
      default: 'bottom end',
    },
  },

  computed: {
    hasMany() {
      return this.value.length > 1
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    actionLabel() {
      return this.$t('assetManagerTitleBrochure' + Capitalize(this.brochureStrategy))
    },
  },
  methods: {
    download(item) {
      AssetBus.emit('download', {
        type: 'brochure',
        strategy: this.brochureStrategy,
        asset: item,
      })
    },
  },
  components: {
    DropdownComponent,
  },
}
</script>
