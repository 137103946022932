<template>
  <LazyLoad>
    <Residentials
      :filter="filter"
      :project="project"
      :properties="properties"
      :title="$path('customFields.title-overrides.residentials', item)"
    />
  </LazyLoad>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import Bus from '@/bus'

import Residentials from '@kvass/residentials'

export default {
  data() {
    return {
      filter: {},
      project: API.project,
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    properties() {
      return KvassConfig.get('siteSettings.featuredResidentialProperties') || []
    },
  },
  created() {
    Bus.on('residentials:filter', filter => (this.filter = filter))
  },
  components: {
    Residentials,
  },
}
</script>
