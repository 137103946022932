<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <Hero class="section__hero" />
      <section class="section section__info">
        <div class="container">
          <div class="grow-1">
            <Openhouse v-if="showOpenhouse" class="mb-6" />
            <Roles />
          </div>
          <Info class="grow-1" />
        </div>
      </section>
      <section v-if="showAdditionalInfo" class="section__additional-info">
        <div class="container">
          <ScrollAnchor value="additional-info" offset="-10rem" :delay="750" />
          <AdditionalInfo />
        </div>
      </section>
      <section v-if="showFlatfinder" class="section section__flatfinder">
        <ScrollAnchor value="flatfinder" offset="-10rem" :delay="750" />
        <Flatfinder />
      </section>
      <section v-if="showResidentials" class="section section__residentials">
        <div class="container">
          <ScrollAnchor value="residentials" offset="-10rem" :delay="750" />
          <Residentials />
        </div>
      </section>
      <section v-if="showResidentialTemplates" class="section section__residential-templates">
        <div class="container">
          <ScrollAnchor value="residential-templates" offset="-10rem" :delay="750" />
          <ResidentialTemplates />
        </div>
      </section>
      <section class="section section__map">
        <div class="container">
          <ScrollAnchor value="map" offset="-10rem" :delay="750" />
          <Map />
        </div>
      </section>
      <section class="section section__lead">
        <div class="container">
          <div class="row wrap g-6">
            <ScrollAnchor value="lead" offset="-10rem" :delay="750" />
            <Lead class="grow-1" />
          </div>
        </div>
      </section>
      <section v-if="$path('media.gallery.length', item)" class="section section__images">
        <div class="container">
          <ScrollAnchor value="gallery" offset="-10rem" :delay="750" />
          <Gallery />
        </div>
      </section>
      <section v-if="$path('media.attachments.length', item)" class="section section__attachments">
        <div class="container">
          <ScrollAnchor value="attachments" offset="-10rem" :delay="750" />
          <Attachments />
        </div>
      </section>
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProjectComponents from '@/components/Project'
import Residentials from '@/components/Residentials'
import BrowserApiMixin from '@/mixins/browser-api'
import ScrollAnchor from '@kvass/scroll-anchor'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),
    showFlatfinder() {
      return this.$path('item.flatfinders.length') && KvassConfig.get('stats.total')
    },
    showResidentials() {
      return KvassConfig.get('stats.total') > 0
    },
    showOpenhouse() {
      if (!this.item.id) return false
      if (this.$path('item.openhouses.length')) return true
      return !KvassConfig.get('siteSettings.hideNoOpenhouse')
    },
    showResidentialTemplates() {
      if (!this.item) return false
      return this.$path('residential-templates.references.length', this.item.customFields)
    },
    showAdditionalInfo() {
      if (!this.item) return false

      const firstItem = this.$path('item.customFields.additional-info.0')
      if (!firstItem) return false

      return !!Object.values(firstItem).filter(Boolean).length
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    ...ProjectComponents,
    Residentials,
    ScrollAnchor,
  },
}
</script>

<style lang="scss">
.project-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }
  .section {
    &__info {
      .container {
        display: grid;
        grid-gap: 6rem;
        grid-template-columns: repeat(2, 1fr);

        @include respond-below('phone') {
          grid-template-columns: 1fr;

          & > *:first-child {
            order: 2;
          }

          & > *:nth-child(2) {
            order: 1;
          }
        }
      }
    }
  }
}
</style>
