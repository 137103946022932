import { path as RPath } from 'ramda'

const Clone = function() {
  return JSON.parse(JSON.stringify.apply(this, arguments))
}

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function getDimensions(url) {
  return new Promise((resolve, reject) => {
    if (!url) return reject(new Error('Url is required'))
    let img = new Image()
    img.onload = function() {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.onerror = reject
    img.src = url
  })
}

function RandomBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function MakeArray(val) {
  if ([undefined, null].includes(val)) return []
  return val instanceof Array ? val : [val]
}

function DownloadUrl(url, name) {
  let link = document.createElement('a')
  link.setAttribute('target', '_blank')
  link.href = url
  document.body.appendChild(link)
  link.click()
  link.remove()
}
function Capitalize(value) {
  return value.charAt(0).toUpperCase() + value.substring(1)
}

function GetCustomField(key, obj = {}, path = '') {
  return RPath([key, ...(path ? path.split('.') : [])], obj)
}

function SplitHtml(value, pattern = '<h2>') {
  if (!value) return []
  let splitter = '<<SPLITTER>>'
  let regex = new RegExp(pattern, 'g')
  return value
    .replace(regex, splitter + pattern)
    .split(splitter)
    .filter(Boolean)
}

export {
  DownloadUrl,
  Clone,
  getCookie,
  getDimensions,
  RandomBetween,
  MakeArray,
  Capitalize,
  GetCustomField,
  SplitHtml,
}
