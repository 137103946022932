<template>
  <div class="project-hero">
    <Slider
      v-if="items.length"
      :value="items"
      class="project-hero__slide"
      :display-thumbnail="true"
      :duration="10000"
      :manual-navigation="true"
    />

    <div v-if="showUpcomingLabel" class="project-hero__coming">
      <div class="project-hero__coming-title">{{ upcomingLabel }}</div>
    </div>
  </div>
</template>

<script>
import Slider from '@kvass/media-render/Slider'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return this.$path('item.media.cover') || []
    },
    stats() {
      return this.$path('item.stats') || {}
    },
    upcomingLabel() {
      if(KvassConfig.get('customLabels.upcoming')) return KvassConfig.get('customLabels.upcoming')

      if(this.stats.saleUpcoming) return this.$t('residentialStatus:sale.upcoming')
      if(this.stats.rentUpcoming) return this.$t('residentialStatus:rent.upcoming')

      return this.$t('upcomingLabel')
    },
    showUpcomingLabel(){
      return this.getStat('total') === 0 || this.getStat('total') === this.getStat('upcoming')
    }
  },
  methods: {
    getStat(key){
      const keys = key instanceof Array ? key : [key]
      return keys.reduce((r, c) => r + KvassConfig.get(`stats.${c}`) || 0, 0)
    }
  },
  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.project-hero {
  $minHeight: 50vh;

  position: relative;
  min-height: $minHeight;
  background-color: css-alpha('primary', 0.1);

  img,
  &__slide {
    position: absolute !important;
    width: 100%;
    height: 100%;

    iframe {
      min-height: $minHeight;
    }
  }

  &__coming {
    position: absolute;
    right: 0;
    bottom: 0;
    font-weight: bold;
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 0 auto;
    width: 100%;
    max-width: 200px;

    &-title {
      margin: 0;
    }
  }
}
</style>
